//自定义组件库
import testOption from '@/components/test/option'
import imgTabsOption from '@/components/imgTabs/option'
import imgListOption from '@/components/imgList/option'
import mCircularOption from '@/components/mCircular/option'



const requireComponent = require.context('./components', false, /\w+.(vue|js)$/)

const components = {
  testOption,
  imgTabsOption,
  imgListOption,
  mCircularOption
}

requireComponent.keys().forEach(fileName => {
  const cmp = requireComponent(fileName).default
  components[cmp.name + 'Option'] = cmp
})

export default {
  components: components
}